import { Vue, Component, Inject, Watch } from 'vue-property-decorator';
import Api from '../../api';
import ModelStore from '../../model-store';
import { LiveLogger } from '../../types';

@Component({})
export default class LogLiveComponent extends Vue {

    @Inject() readonly api!: Api;
    @Inject() readonly model!: ModelStore;

    isAutoScrollEnabled: boolean = true;
    selectedLevel: string = "Debug";
    selectedLogger: string = "*";
    selectedLoggerLevel: string = "Debug";
    loggers: LiveLogger[] = [];

    $refs!: {
        endMarker: HTMLDivElement
    }

    mounted() {
        this.api.fetch<LiveLogger[]>('/api/LiveLog/GetLoggers')
            .then(loggers => {
                this.loggers = loggers;
            });
    }

    @Watch("model.logMessages")
    async handleMessagesChanged() {
        if (this.isAutoScrollEnabled) {
            await this.$nextTick();
            const element = this.$refs.endMarker;
            element.scrollTop = element.scrollHeight;
        }
    }

    enableLiveLog() {
        this.api.call("api/LiveLog/EnableLiveLog/true");
    }

    disableLiveLog() {
        this.api.call("api/LiveLog/EnableLiveLog/false");
    }

    setLoggerLevel() {
        this.api.fetch<LiveLogger[]>("api/LiveLog/SetLoggerLevel",
            {
                method: "POST",
                body: JSON.stringify({ "loggerName": this.selectedLogger, "logLevel": this.selectedLoggerLevel }),
                headers: { "Content-Type": "application/json" }
            })
            .then(loggers => {
                this.loggers = loggers;
            });
    }

    clearLog() {
        this.model.logMessages = [];
    }
}