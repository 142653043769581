import { Vue, Component, Inject } from 'vue-property-decorator';
import Api from '../../api';
import * as Utilities from '../../utilities';

@Component({})
export default class LogViewerComponent extends Vue {

    @Inject() readonly api!: Api;

    logFiles: LogFile[] = [];
    selectedName: string = "";
    logText: string | null = null;
    logLines: LogLine[] = [];
    logDownloadUrl: string = "";

    get hasLog(): boolean {
        return this.selectedName.length > 0;
    }

    mounted() {
        this.api.fetch<LogFile[]>('/api/Log/GetLogFiles')
            .then(newLogFiles => {
                for (const logFile of newLogFiles) {
                    logFile.lastWriteDate = new Date(logFile.lastWriteTime);
                }

                this.logFiles = newLogFiles;
            });
    }

    isSelected(log: LogFile) {
        return this.selectedName === log.name;
    }

    select(log: LogFile) {
        if (this.isSelected(log)) {
            this.closeLog();
        } else {
            this.selectedName = log.name;
            this.logText = `Lade ${log.name}...`; 
            this.api.fetch<LogLine[]>(`/api/Log/GetLogLines/${log.name}`)
                .then(data => {
                    for (const d of data) {
                        d.timeDate = new Date(d.time);
                    }
                    this.logLines = data;
                });
        }
    }

    closeLog() {
        this.selectedName = "";
        this.logText = null;
        this.logLines = [];
    }

    saveLog() {
        this.api.call(`/api/Log/GetLogFile/${this.selectedName}`)
            .then(result => result.blob())
            .then(blob => Utilities.downloadBlob(blob, this.selectedName));
    }
}

interface LogFile {
    name: string;
    length: number;
    lastWriteTime: string;
    lastWriteDate: Date;
}

interface LogLine {
    time: string;
    timeDate: Date;
    level: string;
    category: string;
    message: string;
}